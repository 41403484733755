<template>
  <div class="addressPopup common-pop">
    <el-dialog :title="title" :visible.sync="takeVisible" :close-on-click-modal="false" width="85vw">
      <div class="searchArea">
        <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
          <el-form-item label="地址库" prop="type">
            <el-select class="item-choose shortInput"  v-model="dataForm.type" style="width: 100%;" filterable clearable placeholder="请选择">
              <el-option label="公共库" value="0"></el-option>
              <el-option label="私有库" value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="工厂名称">
            <el-input v-model="dataForm.simpleName"  class="shortInput" placeholder="请输入工厂名称" clearable></el-input>
          </el-form-item>
          <el-form-item label="详细地址">
            <el-input v-model="dataForm.fullAddress" class="shortInput" placeholder="请输入详细地址" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button @click="getDataList()" type="primary">查询</el-button>
            <el-button @click="addAddressInfo()" v-if="newAuth('customer:book:add')" type="primary">新增</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table :data="takeAddress" max-height="450" border>
        <el-table-column label="选择" width="35" align="center">
          <template slot-scope="scope">
            <el-radio v-model="selectAddress"  :label="scope.$index" @change.native="getSelectAddress(scope.row)" class="hideLabel"></el-radio>
          </template>
        </el-table-column>
        <el-table-column property="type" label="地址库"  :show-overflow-tooltip="true"  width="50">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.type === 0" size="small" type="danger">公共库</el-tag>
            <el-tag v-else size="small">私有库</el-tag>
          </template>
        </el-table-column>
        <el-table-column property="simpleName" label="工厂名称"  :show-overflow-tooltip="true"  width="145"></el-table-column>
<!--        <el-table-column property="street" label="提卸货点"  :show-overflow-tooltip="true"  width="100"></el-table-column>-->
        <el-table-column property="fullAddress" label="详细地址"  :show-overflow-tooltip="true"></el-table-column>
        <el-table-column property="remark" label="注意事项" :show-overflow-tooltip="true" width="220"></el-table-column>
        <el-table-column property="contactName" label="联系人" width="90" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column property="contactPhone" label="联系人电话" width="120" :show-overflow-tooltip="true"></el-table-column>
      </el-table>
       <span slot="footer" class="dialog-footer">
        <el-button @click="takeVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
    <add-address ref="add" @refreshDataList="getDataList"></add-address>
  </div>
</template>
<script>
import addAddress from './address-add'
export default {
  components: {
    addAddress
  },
  data () {
    return {
      takeVisible: false,
      takeAddress: [],
      dataForm: {
        simpleName: null,
        type: null,
        status: 1,
        fullAddress: null
      },
      selectAddress: null,
      addressType: null, // 拿货1， 卸货2
      selectAddressRow: null, // 保存选择的值
      customerId: null,
      title: null,
      infoIdx: null // 第几条信息
    }
  },
  methods: {
    // 选择地址
    getSelectAddress (row) {
      this.selectAddressRow = row
    },
    init (flag, customerId, index) {
      this.takeVisible = true
      this.selectAddressRow = null
      this.selectAddress = null
      this.dataForm.simpleName = null
      this.dataForm.address = null
      this.customerId = customerId
      this.infoIdx = index
      this.addressType = flag
      this.getDataList(flag)
      if (flag === 1) {
        // 拿货地址
        this.title = '提货地址薄'
      } else if (flag === 2) {
        // 卸货地址
        this.title = '卸货地址薄'
      }
    },
    addAddressInfo () {
      this.$refs.add.init('add', this.customerId, this.addressType)
    },
    // 获取地址信息
    getDataList () {
      // this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/customerAddress/findAll'),
        method: 'get',
        params: this.$http.adornParams({
          'type': this.dataForm.type,
          'fullAddress': this.dataForm.fullAddress,
          'simpleName': this.dataForm.simpleName,
          'addressType': this.addressType,
          'status': this.dataForm.status,
          'customerId': this.customerId
        })
      }).then(({ data }) => {
        if (data) {
          this.takeAddress = data
        } else {
          this.takeAddress = []
        }
      })
    },
    // 提交地址
    submit () {
      if (this.selectAddressRow != null) {
        // 将选择的地址， 当前操作的索引，及属于提货还是卸货信息带回去
        this.$parent.getAddress(this.selectAddressRow, this.infoIdx, this.addressType)
        this.takeVisible = false
      } else {
        this.$message.error('请选择一个地址')
      }
    }
  }
}
</script>
<style lang="less">
.addressPopup {
  .hideLabel {
    .el-radio__label {
      display: none
    }
  }
  thead th .cell {
    height: 32px;
    line-height: 32px;
}
  .shortInput {
    width: 140px;
  }
  .searchArea {
    background: #f6f6f6;
    padding-top: 5px;
    padding-left: 5px;
  }
  .el-dialog__header {
    padding: 13px 18px 0px;
  }
  .el-button {
    padding: 6px 10px;
  }
  .el-dialog__body {
      padding: 12px 20px;
  }
  .el-table--medium td, .el-table--medium th {
    padding: 3px 0;
  }
}
</style>
